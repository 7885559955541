<template>
<div id="invoice">
  <div style="padding:5px solid red"
   >
  <!-- <div id="appp" > -->

     
    <div class="pb-3">
      <div>
        <h4 class="bold">REPORT VIEW</h4>
      </div>
      <!-- <div><v-btn @click="makePDF()"> download pdf</v-btn></div> -->
      <!-- <div><v-btn @click="makePDF2() "  > download pdf</v-btn></div> -->
      <!-- <div><v-btn @click="printFacture()"> download pdf...</v-btn></div> -->
    </div>

    <div id="googlemap"></div>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <ServerError v-if="ServerError" />

      <Details />
    </div>
  </div>
</div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBTkuPaji75FkC_WBegL4cupA9_HJWNAuE&libraries=visualization,drawing">
</script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.js"></script>
<script>
import axios from "axios";
import Details from "./dataPage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  components: {
    Details,
  },
  data() {
    return {
      animalRescue: [],
      defaultLAt: 0,
      defaultLON: 0,
      icons: "",
      totalLength: 0,
      page: 1,
      appLoading: false,
      ServerError: false,
      user: [],
      records: [],
      gmap: null,
      pagelength: 0,
      allData: [],
      headers: [
        { text: "Date", value: "create_date" },
        {
          text: "Boat name",
          align: "start",
          sortable: false,
          value: "boatname",
        },
        { text: "Boat No", sortable: false, value: "boatno" },
        { text: "Spot", sortable: false, value: "field" },
        { text: "Expected length (ft)", value: "length" },
      ],
      //map data
      latt: null,
      lonn: null,
      Trails: [],
      flightPlanCoordinates: [],
      flight: [],

      CORD: {
        lat: "",
        lng: "",
      },
    };
  },
  watch: {
    page() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    printFacture() {
      var pdf = new jsPDF();
      var element = document.getElementById("invoice");
      var width = element.style.width;
      // console.log("width=", element.style.width);
      var height = element.style.height;
      html2canvas(element).then((canvas) => {
        var image = canvas.toDataURL("image/png/jpg/JPG");
        // pdf.addImage(image, 'jpg', 5, 5 , 200, 300);
        pdf.addImage(image, "JPEG", 5, 5, width, height);
        pdf.save("invoice" + ".pdf");
      });
    },

    makePDF2() {
      window.o
      const invoice = document.getElementById("invoice");
      // console.log(invoice);
      // console.log(window);  
      // console.log("sd",window);
      // console.log("l",window);
      html2pdf().from(invoice).save();
    },


    makePDF() {
      window.html2canvas = html2canvas;
      var doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#appp"), {
        callback: function (pdf) {
          pdf.save("mypdf.pdf");
        },
      });
    },

    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },
    getMap() {
      this.gmap = new google.maps.Map(document.getElementById("googlemap"), {
        // center: { lat: 9.5260093, lng: 76.8144186 },
        center: { lat: this.defaultLAt, lng: this.defaultLON },
        // center: { lat: this.defaultLON, lng: this.defaultLAt },
        mapTypeId: "satellite",
        zoom: 17,
      });
      //     const flightPlanCoordinates = [
      //   { lat: 37.772, lng: -122.214 },
      //   { lat: 21.291, lng: -157.821 },
      //   { lat: -18.142, lng: 178.431 },
      //   { lat: -27.467, lng: 153.027 },
      // ];
      // const flightPath = new google.maps.Polyline({
      //   path: flightPlanCoordinates,
      //   geodesic: true,
      //   strokeColor: "#FF0000",
      //   strokeOpacity: 1.0,
      //   strokeWeight: 2,
      // });

      // flightPath.setMap(gmap);
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/view/report/details",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 25,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
            this.participents = response.data.data.participents;
            this.snareReports = response.data.reports;
            this.defaultLAt = response.data.data.startGps[1];
            this.defaultLON = response.data.data.startGps[0];
            this.Trails = response.data.data.gps;
            this.animalRescue = response.data.animalRescue;

            for (let i = 0; i < this.Trails.length; i++) {
              this.CORD.lat = this.Trails[i].location[1];
              // console.log("this.Trails[i].location[1]=",this.Trails[i].location[1])
              this.CORD.lng = this.Trails[i].location[0];
              // console.log("this.Trails[i].location[1]=",this.Trails[i].location[0])

              this.flight.push(JSON.parse(JSON.stringify(this.CORD)));
              // console.log("this.Trails[i].location[1]=",this.Trails[i].location[0])

              // console.log("this.CORD=", this.CORD);

              this.CORD.lat = "";
              this.CORD.lon = "";
            }
            // console.log("this.flight=", this.flight);

            this.getMap();

            // this.flightPlanCoordinates = [
            //   { lat: 37.772, lng: -122.214 },
            //   { lat: 21.291, lng: -157.821 },
            //   { lat: -18.142, lng: 178.431 },
            //   { lat: -27.467, lng: 153.027 },
            // ];
            // flight

            this.flightPlanCoordinates = this.flight;
            // console.log(" this.flight222=" , this.flight)

            const flightPath = new google.maps.Polyline({
              path: this.flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#5282e3",
              strokeOpacity: 1.0,
              strokeWeight: 5,
            });

            flightPath.setMap(this.gmap);

            for (let i = 0; i < response.data.reports.length; i++) {
              this.latt = response.data.reports[i].snareLocation[1];
              this.lonn = response.data.reports[i].snareLocation[0];
              let l = i + 1;
              this.icons = {
                // url: require("../../assets/images/whale.svg"),
                // url: require("../../assets/images/markers/r4.png"),
                url: require("../../assets/images/ymarker.png"),

                size: new google.maps.Size(30, 50),
                scaledSize: new google.maps.Size(20, 40),
                anchor: new google.maps.Point(0, 50),
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                icon: this.icons,
              });

              // if(response.data.reports[i].snareTypes == "Multiple Loops")
              // {
              //   var z = 0;
              // + " Loop count-" + "<b>" + response.data.reports[i].snareLoop +"</b>" + "<br/>"
              const infowindow = new google.maps.InfoWindow({
                content: String(
                  "<b style=color:black;font-size:15px;margin-bottom:3px  >" +
                    "Snare Report" +
                    "</b> <br/>" +
                    "Snare Type - " +
                    "<b style=color:green >" +
                    response.data.reports[i].snareTypes.name +
                    "</b>" +
                    (response.data.reports[i].snareLoop
                      ? " ,Loop count-" + response.data.reports[i].snareLoop
                      : "") +
                    "<br/>" +
                    //  + " Loop count-" + "<b>" + response.data.reports[i].snareLoop +"</b>" + "<br/>"
                    " Snare No -" +
                    "<b>" +
                    l +
                    "</b>" +
                    "<br/>" +
                    " Near by village(m) -" +
                    "<b>" +
                    response.data.reports[i].nearByVillageDistance +
                    "</b>" +
                    "<br/>" +
                    " Near by water source(m) -" +
                    "<b>" +
                    response.data.reports[i].nearByWaterSource +
                    "</b>" +
                    "<br/>" +
                    " Forest Type -" +
                    "<b>" +
                    response.data.reports[i].forestType +
                    "</b>" +
                    "<br/>" +
                    " Land Scape -" +
                    "<b>" +
                    response.data.reports[i].landScape +
                    "</b>" +
                    "<br>"
                ),

                maxWidth: 500,
                pixelOffset: new google.maps.Size(-53, 0),
              });
              // }

              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }
            // for (let i = 0; i < response.data.data.gps.length; i++) {
            //   this.latt = response.data.data.gps[i].location[1];
            //   this.lonn = response.data.data.gps[i].location[0];
            //   this.icons = {
            //     url: require("../../assets/images/markers/r6.png"),

            //     size: new google.maps.Size(15, 20),
            //     scaledSize: new google.maps.Size(10, 10),
            //     anchor: new google.maps.Point(0, 50),
            //   };
            //   this.marker = new google.maps.Marker({
            //     position: { lat: this.latt, lng: this.lonn },
            //     map: this.gmap,
            //     icon: this.icons,
            //   });
            // }
            /////////////////////////////////////////////////////////////////////////////////////
            for (let i = 0; i < response.data.animalRescue.length; i++) {
              this.latt = response.data.animalRescue[i].animalLocation[1];
              this.lonn = response.data.animalRescue[i].animalLocation[0];
              let l = i + 1;
              this.icons = {
                // url: require("../../assets/images/whale.svg"),
                // url: require("../../assets/images/markers/r4.png"),
                url: require("../../assets/images/markers/redMarker.png"),

                size: new google.maps.Size(30, 50),
                scaledSize: new google.maps.Size(20, 40),
                anchor: new google.maps.Point(0, 50),
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                icon: this.icons,
              });

              // if(response.data.reports[i].snareTypes == "Multiple Loops")
              // {
              //   var z = 0;
              // + " Loop count-" + "<b>" + response.data.reports[i].snareLoop +"</b>" + "<br/>"
              const infowindow = new google.maps.InfoWindow({
                content: String(
                  "<b style=color:black;font-size:15px;margin-bottom:3px  >" +
                    "Animal Report" +
                    "</b> <br/>" +
                    "Animal Sps - " +
                    "<b style=color:green >" +
                    response.data.animalRescue[i].animalSps +
                    "</b>" +
                    //  + " Loop count-" + "<b>" + response.data.reports[i].snareLoop +"</b>" + "<br/>"

                    "<br/>" +
                    " Animal Condition -" +
                    "<b>" +
                    response.data.animalRescue[i].animalCondition +
                    "</b>" +
                    "<br/>" +
                    " Animal Stage -" +
                    "<b>" +
                    response.data.animalRescue[i].animalStage +
                    "</b>" +
                    "<br/>" +
                    " Report Type -" +
                    "<b>" +
                    response.data.animalRescue[i].reportType +
                    "</b>" +
                    "<br>" +
                    " Snare Type -" +
                    "<b>" +
                    response.data.animalRescue[i].snareTypes.name +
                    "</b>" +
                    "<br>"
                ),

                maxWidth: 500,
                pixelOffset: new google.maps.Size(-53, 0),
              });
              // }

              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }

            ////////////////////////////////////////////////////////////////////////////////////////////////
            {
              this.latt = response.data.data.startGps[1];
              this.lonn = response.data.data.startGps[0];
              this.icons = {
                url: require("../../assets/images/green.png"),

                size: new google.maps.Size(100, 60),
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(12, 25),
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                icon: this.icons,
              });

              const infowindow = new google.maps.InfoWindow({
                content: String("Start Point"),
                maxWidth: 500,
                pixelOffset: new google.maps.Size(-40, 0),
              });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }
            {
              this.latt = response.data.data.endGps[1];
              this.lonn = response.data.data.endGps[0];
              this.icons = {
                url: require("../../assets/images/red.png"),
                // url: require("../../assets/images/markers/redMarker.png"),
                size: new google.maps.Size(100, 60),
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(12, 25),
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                // icon: this.icons,
                icon: this.icons,
              });

              const infowindow = new google.maps.InfoWindow({
                content: String("End Point"),
                maxWidth: 500,
                pixelOffset: new google.maps.Size(-40, 0),
              });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
#googlemap {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
